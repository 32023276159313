import * as React from "react"
import { Helmet } from "react-helmet"
import "@fontsource/kanit"
import 'animate.css';

const IndexPage = () => {
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'th',
        }}
      >
        <title>Warat K.</title>
        <meta property='og:image' content='/og-banner.png' />
        <meta property='og:locale' content='en_US' />
        <meta property='og:type' content='website' />
        <meta property='og:title' content='Warat Kaweepornpoj' />
        <meta property='og:description' content="Full-stack Software Engineer based in Bangkok, Thailand." />
        <meta property='og:url' content='https://warat.win' />
        <meta name="theme-color" content="#000000" />
      </Helmet>
      <div className="w-full fixed h-14 navbar flex items-center z-10">
        <div className="m-auto w-full xl:max-w-screen-lg lg:max-w-screen-md md:max-w-screen-sm max-w-xxs flex justify-between items-center">
          <div className="text-white font-bold md:text-2xl text-xl">
            Warat K.
          </div>
        </div>
      </div>
      <div className="min-h-screen w-full bg-black flex flex-col items-center justify-center pt-24 pb-16 md:pt-18">
        <div className="flex-2" />
        <video className="m-auto flex-2 flex-grow-0" width="640" height="480" autobuffer="true" preload="true" autoPlay muted>
          <source type='video/mp4' src="/video.mp4"></source>
        </video>
        <div className="container mx-auto max-w-4xl flex-1 text-center">
          <p className="text-gray-50 md:text-6xl text-5xl font-bold m-6 animate__animated animate__fadeInUp animate__delay-1s">
            WARAT KAWEEPORNPOJ
          </p>
          <p className="text-gray-50 text-xl m-6 animate__animated animate__fadeInUp animate__delay-2s">
            Software engineer based in Bangkok, Thailand.<br />
            Passionate in making extraordinary website and video games.
          </p>

        </div>
      </div>
      <div className="min-h-screen w-full bg-black flex flex-col">
        <a href="https://www.linkedin.com/in/waratkawee/" title="linkedin" target="_blank" className="text-gray-50 md:text-3xl text-2xl text-center hover:underline">
          LinkedIn Profile >
        </a>
        <div className="container mx-auto px-3 max-w-screen-xl m-28">
          <div className="rounded-3xl h-auto p-10 md:my-5 my-3 bg-gradient-to-br from-pink-800 to-gray-900">
            <div className="grid md:grid-cols-12 grid-rows-none auto-rows-max">
              <div className="md:col-span-8 py-12">
                <div className="text-gray-50 md:text-3xl text-xl">Experienced in crafting various kind of software including</div>
                <div className="text-pink-100 md:text-6xl text-4xl font-bold mt-4">Web Application</div>
                <div className="text-pink-200 md:text-6xl text-4xl font-bold mt-2">Hybrid Application</div>
                <div className="text-pink-300 md:text-6xl text-4xl font-bold mt-2">Game Development</div>
                <div className="text-pink-400 md:text-6xl text-4xl font-bold mt-2">Chatbot</div>
              </div>
            </div>
          </div>

          <div className="grid md:grid-cols-2 md:gap-6 gap-3">
            <div className="rounded-3xl h-auto p-10 bg-gradient-to-b from-red-900 to-gray-900">
              <div className="text-gray-50 text-xl font-bold">Experiences</div>
              <img width="64" height="64" src="/icon-web-programming.png" alt="Software Engineer" className="w-16 mt-16" />
              <div className="text-gray-100 md:text-2xl text-xl my-4">
                Senior Software Engineer
                <br /><span className="text-gray-400">November 2018 - Present</span>
                <br />Agoda Services Co., Ltd.
              </div>
              <img width="64" height="64" src="/icon-rubik.png" alt="Game Designer" className="w-16 mt-16" />
              <div className="text-gray-100 md:text-2xl text-xl my-4">
                Game Designer
                <br /><span className="text-gray-400">June 2018 - October 2018</span>
                <br />True Axion Interactive
              </div>
            </div>
            <div className="rounded-3xl flex flex-col h-auto p-10 bg-gradient-to-b from-indigo-900 to-gray-900">
              <div className="text-gray-50 text-xl font-bold self-start">Skills</div>
              <div className="text-gray-100 text-xl mt-6">
                HTML&CSS / Javascript / TypeScript / C# / Python<br />
                React / Redux / React-native / Expo<br />
                .NET Core / Unity / SciKit-Learn / NLTK<br />
                Git / Firebase <br />
              </div>
              <div className="md:w-9/12 mx-auto md:my-0 my-8 h-full flex flex-col justify-center">
                <img src="/app_collection.png" alt="" />
              </div>
            </div>
          </div>
        </div>

        <div className="container mx-auto max-w-screen-xl md:my-20 my-none">
          <div className="text-gray-100 md:text-8xl text-4xl font-bold mb-12 text-center">Education</div>
          <div className="m-auto pt-8 px-8 max-w-screen-lg">
            <span className="text-blue-200 text-4xl font-bold">King Mongkut's University of Technology Thonburi</span><br />
            <span className="text-gray-200 text-3xl">B.Eng. in Computer Engineering</span><br />
            <span className="text-gray-400 text-2xl">Class of 2018</span>
          </div>
          <div className="m-auto pt-16 px-8 max-w-screen-lg">
            <span className="text-blue-300 text-4xl font-bold">Bodindecha (Sing Singhaseni) School</span><br />
            <span className="text-gray-200 text-3xl">High School Diploma</span><br />
            <span className="text-gray-400 text-2xl">Class of 2014</span>
          </div>
        </div>

        <div className="container mx-auto max-w-screen-xl m-20">
          <div className="grid md:grid-cols-3 md:my-3">
            <div className="rounded-3xl p-10 pb-20 mx-3 mb-3 bg-apple-gray">
              <div className="text-gray-50 text-xl font-bold">Typing Speed</div>
              <div className="h-full flex flex-col content-center justify-center pt-2">
                <div>
                  <div className="text-gray-50 text-8xl m-auto text-center font-bold">
                    100
                  </div>
                  <div className="text-gray-50 text-2xl mt-2 text-center font-bold">
                    words per minute (WPM)
                  </div>
                  <div className="text-gray-50 text-lg mt-2 text-center">
                    with touch typing skill
                  </div>
                </div>
              </div>
            </div>
            <div className="rounded-3xl p-10 pb-20 mx-3 mb-3 bg-apple-gray">
              <div className="text-gray-50 text-xl font-bold">Language Proficiency</div>
              <div className="text-gray-50 text-4xl mt-16 text-center">
                Expired TOEIC Score<br />
              </div>
              <div className="text-gray-50 text-8xl mt-2 mb-4 text-center font-bold">
                955
              </div>
            </div>
            <div className="rounded-3xl p-10 mx-3 mb-3 bg-apple-gray">
              <div className="text-gray-50 text-xl font-bold">Languages</div>
              <div className="h-full flex flex-col content-center justify-center pt-8">
                <div>
                  <div className="text-gray-50 text-6xl text-center font-bold">
                    ภาษาไทย
                  </div>
                  <div className="text-gray-50 mt-8 mb-16 text-6xl text-center font-bold">
                    English
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="grid md:grid-cols-3">
            <div className="rounded-3xl md:col-span-3 p-10 pb-20 mx-3 mb-3 bg-gradient-to-b from-green-900 to-gray-900">
              <div className="text-gray-50 text-xl font-bold">Achievements</div>
              <div className="md:text-6xl text-3xl mt-16 text-center md:leading-tight text-green-300">
                <span className="font-bold text-white">Winner</span>, National Software Contest <span className="md:text-2xl text-lg">2018</span><br />
                <span className="font-bold text-white">Finalist</span>, Microsoft Imagine Cup <span className="md:text-2xl text-lg">2018</span><br />
                <span className="font-bold text-white">Honorable Mention</span>, National Software Contest <span className="md:text-2xl text-lg">2016</span><br />
                <span className="font-bold text-white">Winner</span>, Young Webmaster Camp 13 <span className="md:text-2xl text-lg">2016</span>
              </div>
            </div>
          </div>
        </div>

        <div className="container mx-auto max-w-screen-lg md:p-16 p-12">
          <div className="text-gray-400 md:text-8xl text-4xl font-bold">Looking for</div>
          <div className="text-gray-100 md:text-8xl text-4xl font-bold mb-12">Stunning Projects?</div>
          <div className="text-gray-400 text-3xl max-w-4xl leading-tight">
            I have done variety kind of personal projects. From web application to video games, these are the works that I am proud of:
          </div>
        </div>
        {/* <div className="container md:max-w-screen-xl md:h-192 h-64 m-auto">
          <div className="w-10/12 md:max-h-192 h-full m-auto">
            <video className="m-auto flex-2 flex-grow-0 shadow-xl" width="960" height="720" autobuffer="true" preload="true" autoPlay muted controls>
              <source type='video/webm' src="/chatbot.webm"></source>
            </video>
          </div>
        </div> */}
        <div className="container mx-auto max-w-screen-xl mb-20">
          <div className="grid md:grid-cols-2 md:gap-0 md:gap-y-3 sm:gap-3">
            <div className="rounded-3xl col-span-1 p-10 pb-32 mx-3 mb-3 bg-gradient-to-br from-gray-900 to-yellow-300 relative">
              <img src="/console.png" alt="Game" width="32" height="32" className="w-16 md:mt-2" />
              <div className="text-gray-100 md:text-2xl text-xl my-6">
                <span className="font-bold">Chanom: AI-Powered Charades</span>
                <br /><span className="text-gray-400">Word game application</span>
              </div>
              <div className="text-gray-100 md:text-2xl text-xl my-6">
                Charades game with generative AI integration. 
              </div>
              <Button text="App Store" link="https://apps.apple.com/th/app/chanom-ai-powered-charades/id6466197197" />
              <Button text="Play Store" link="https://play.google.com/store/apps/details?id=com.winwanwon.Chanom" />
            </div>
            <div className="rounded-3xl col-span-1 p-10 pb-32 mx-3 mb-3 bg-gradient-to-br from-gray-500 to-gray-800 relative">
              <img src="/console.png" alt="Game" width="32" height="32" className="w-16 md:mt-2" />
              <div className="text-gray-100 md:text-2xl text-xl my-6">
                <span className="font-bold">TABORO: Forbidden Word</span>
                <br /><span className="text-gray-400">Word game application</span>
              </div>
              <div className="text-gray-100 md:text-2xl text-xl my-6">
                Word game for iOS/Android. Inspired by "Forbidden Word" game on <a href="https://www.youtube.com/@thepleelachannel" target="_blank">ThepLeela</a> youtube channel.
              </div>
              <Button text="App Store" link="https://apps.apple.com/th/app/taboo-%E0%B8%84%E0%B8%B3%E0%B8%95-%E0%B8%AD%E0%B8%87%E0%B8%AB-%E0%B8%B2%E0%B8%A1/id6443472967" />
              <Button text="Play Store" link="https://play.google.com/store/apps/details?id=com.pubstagames.taboo" />
            </div>
            <div className="rounded-3xl col-span-1 p-10 pb-32 mx-3 mb-3 bg-crowdabout relative">
              <img src="/console.png" alt="Game" width="32" height="32" className="w-16 md:mt-2" />
              <div className="text-gray-100 md:text-2xl text-xl my-6">
                <span className="font-bold">CROWDABOUT</span>
                <br /><span className="text-gray-400">Video game</span>
              </div>
              <div className="text-gray-100 md:text-2xl text-xl my-6">
                Minimal casual game on iOS/Android platform. Built using Unity Game Engine with Google Play Game Services and Apple Game Center integration.
              </div>
              <Button text="App Store" link="https://bit.ly/crowdaboutios" />
              <Button text="Play Store" link="https://bit.ly/crowdaboutandroid" />
            </div>
            <div className="rounded-3xl col-span-1 p-10 pb-32 mx-3 mb-3 bg-pokero relative">
              <img src="/icon-web-programming.png" alt="Game" width="32" height="32" className="w-16 md:mt-2" />
              <div className="text-gray-100 md:text-2xl text-xl my-6">
                <span className="font-bold">POKERO</span>
                <br /><span className="text-gray-400">Web Application</span>
              </div>
              <div className="text-gray-100 md:text-2xl text-xl my-6">
                Planning poker web application for scrum's task estimation purpose
              </div>
              <Button text="Visit" link="https://pokercardestimate.web.app" />
              <Button text="Github" link="https://github.com/winwanwon/pokero" />
            </div>
            <div className="rounded-3xl col-span-1 p-10 pb-32 mx-3 mb-3 bg-gradient-to-br from-blue-900 to-gray-900 relative">
              <img src="/comment.png" alt="AI" width="32" height="32" className="w-16 md:mt-2" />
              <div className="text-gray-100 md:text-2xl text-xl my-6">
                <span className="font-bold">HPChat: Hospital receiptionist at your fingertips</span>
                <br /><span className="text-gray-400">AI Chatbot</span>
              </div>
              <div className="text-gray-100 md:text-2xl text-xl my-6">
                AI-powered chatbot developed using Natural Language Processing, Machine Learning, Search Engine, and Image Processing techniques to be able to understand Thai and English language input and respond as a hospital receptionist
              </div>
              <Button text="Postmortem (TH)" link="https://medium.com/@winwanwon/the-making-of-hpchat-7d43ee2e7810" />
            </div>
            <div className="rounded-3xl col-span-1 p-10 pb-32 mx-3 mb-3 bg-annex relative">
              <img src="/console.png" alt="Game" width="32" height="32" className="w-16 md:mt-2" />
              <div className="text-gray-100 md:text-2xl text-xl my-6">
                <span className="font-bold">ANNEX</span>
                <br /><span className="text-gray-400">Video game</span>
              </div>
              <div className="text-gray-100 md:text-2xl text-xl my-6">
                Twin-stick Shooter game created during GMTK Game Jam 2021 in a theme <i>Joined Together</i>
              </div>
              <Button text="Play" link="https://winwanwon.itch.io/annex" />
            </div>
            <div className="rounded-3xl col-span-1 p-10 pb-32 mx-3 mb-3 bg-44 relative">
              <img src="/console.png" alt="Game" width="32" height="32" className="w-16 md:mt-2" />
              <div className="text-gray-100 md:text-2xl text-xl my-6">
                <span className="font-bold">4/4</span>
                <br /><span className="text-gray-400">Video game</span>
              </div>
              <div className="text-gray-100 md:text-2xl text-xl my-6">
                Small puzzle game created during GMTK Game Jam 2020 in a theme <i>Out of Control</i>
              </div>
              <Button text="Play" link="https://winwanwon.itch.io/44" />
            </div>
            <div className="rounded-3xl col-span-1 p-10 pb-32 mx-3 mb-3 bg-shitaro relative">
              <img src="/console.png" alt="Game" width="32" height="32" className="w-16 md:mt-2" />
              <div className="text-gray-100 md:text-2xl text-xl my-6">
                <span className="font-bold">SHITARO</span>
                <br /><span className="text-gray-400">Video game</span>
              </div>
              <div className="text-gray-100 md:text-2xl text-xl my-6">
                2D Side-scrolling game about a cat fixing his spaceship created during Global Game Jam 2020 in a theme <i>Repair</i>
              </div>
              <Button text="Play" link="https://globalgamejam.org/2020/games/shitaro-1" />
            </div>
          </div>
        </div>
      </div>

      <div className="w-full bg-black bg-opacity-95 flex flex-col py-16 px-8">
        <div className="grid md:grid-cols-9 gap-y-16 grid-cols-2 text-gray-50 text-xl text-center leading-loose ">
          <Social
            isStart={true}
            text="Github"
            linkText="Check out"
            icon="github"
            link="https://www.github.com/winwanwon"
          />
          <Social
            text="Twitter"
            linkText="Explore"
            icon="twitter"
            link="https://www.twitter.com/winwanwon"
          />
          <Social
            text="Instagram"
            linkText="See pics"
            icon="instagram"
            link="https://www.instagram.com/winwanwon"
          />
          <Social
            text="Medium"
            linkText="Read articles"
            icon="medium"
            link="https://www.medium.com/@winwanwon"
          />
          <Social
            text="Linkedin"
            linkText="Hire me"
            icon="linkedin"
            link="https://www.linkedin.com/in/waratkawee"
          />
        </div>
        <div className="container mx-auto border-b opacity-20 my-16" />
        {/* <span className="text-yellow-100"> */}
        <div className="m-auto pt-8">
          <a href="https://webring.wonderful.software#warat.win" title="วงแหวนเว็บ">
            <img
              alt="วงแหวนเว็บ"
              width="24"
              height="24"
              src="/webring.white.svg"
            />
          </a>
        </div>
      </div>
    </>
  )
}

const Social = ({ text, linkText, icon, link, isStart }) => {
  return (
    <div className={`${isStart && 'md:col-start-3'} col-span-1`}>
      <img width="32" height="32" src={`/${icon}.png`} alt={`${icon}`} className="w-8 h-8 opacity-60 mx-auto mb-3" />
      {text}
      <a href={link} title="linkedin" target="_blank" className="text-sm mt-2 block text-blue-400 hover:underline">{linkText} ></a>
    </div>
  )
}


const Button = ({ text, link }) => {
  return (
    <a href={link} title={text} target="_blank">
      <div className="border-2 border-white rounded-3xl px-3 py-1 mr-2 text-white w-max inline">
        {text}
      </div>
    </a>
  )
}

const BlueButton = ({ text, link }) => {
  return (
    <a href={link} title={text} target="_self">
      <div className="text-sm bg-blue-600 rounded-3xl px-3 py-1 text-white w-max">
        {text}
      </div>
    </a>
  )
}

export default IndexPage
